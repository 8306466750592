<template>
  <div class="content-searchbar-transation-account">
    <div class="content-searchbar-textfield">
      <!-- #region Campo de filtro sSearch -->
      <v-text-field
        v-model="sSearch"
        label="Buscador de folio"
        placeholder="Búsqueda general"
        class="global-text-field text-field-search"
        color="var(--primary-color-border-input)"
        background-color="var(--primary-color-menu)"
        persistent-placeholder
        outlined
        height="50px"
      >
        <template v-slot:append>
          <v-icon color="#7C8088" size="17px"> mdi-magnify </v-icon>
        </template>
      </v-text-field>
      <!-- #endregion Campo de filtro sSearch -->
      <v-menu
        ref="menu"
        v-model="menu"
        attach
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="sDates"
            outlined
            chips
            clearable
            class="global-text-field global-text-field-date"
            label="Seleccionar rango de fechas"
            placeholder="Seleccionar rango de fechas..."
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            v-bind="attrs"
            v-on="on"
            readonly
          >
            <template slot="append">
              <v-icon @click="menu = true">mdi-calendar-blank</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          :max="sMaxDate"
          range
          no-title
          locale="es"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
          <v-btn
            :disabled="!bAddDates"
            text
            color="blue"
            @click="$refs.menu.save(dates), save(dates)"
          >
            <span :class="bAddDates ? 'color-yellow-global' : ''">Guardar</span>
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-spacer></v-spacer>
    <div class="content-btn-filter-add">
      <div class="content-filter">
        <v-menu
          bottom
          min-width="450px"
          :close-on-content-click="false"
          offset-y
          rounded="lg"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="btn-filter-raw-material global-btn-neutral">
              <v-icon> mdi-tune-vertical </v-icon>
            </v-btn>
          </template>
          <v-card class="content-filter-menu">
            <div class="pa-5">
              <v-row>
                <!--TIPO DE TRANSACCIÓN-->
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <p class="title-filter">Tipo de transacción</p>
                  <div class="content-major-minor">
                    <v-checkbox
                      v-for="(item, index) in itemTransation"
                      :key="index"
                      v-model="sTransactionType"
                      :value="item.value"
                      class="global-checkbox"
                      color="var(--primary-color-text-yellow)"
                    >
                      <template v-slot:label>
                        <span class="text-checkbox">{{ item.text }}</span>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>

                <!-- TIPO DE REGISTRO -->
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <p class="title-filter">Tipo de registro</p>
                  <div class="content-entries">
                    <v-checkbox
                      v-for="(item, index) in itemRegistrationType"
                      :key="index"
                      v-model="sRegistrationType"
                      :value="item.value"
                      class="global-checkbox"
                      color="var(--primary-color-text-yellow)"
                    >
                      <template v-slot:label>
                        <span class="text-checkbox">{{ item.text }}</span>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-menu>
      </div>
      <div v-show="bAdminAccount" class="content-btn-add-transation">
        <v-btn class="global-btn-primary" @click="addTransation()">
          Registrar transacción
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sSearch: "",
      dates: [],
      sDates: "",
      menu: false,
      sMaxDate: new Date().toISOString().slice(0, 10),
      bAddDates: false,
      itemTransation: [
        {
          text: "Entrada",
          value: "DEPOSIT",
        },
        {
          text: "Salida",
          value: "WITHDRAW ",
        },
      ],
      sTransactionType: "",
      itemRegistrationType: [
        {
          text: "Registro manual",
          value: "MANUALREGISTER",
        },
        {
          text: "Automático - Venta",
          value: "SALESORDER",
        },
        {
          text: "Automático - Compra",
          value: "PURCHASEORDER",
        },
      ],
      sRegistrationType: "",
    };
  },
  methods: {
    addTransation() {
      this.$emit("setDialogAddTransation");
    },
    save(date) {
      this.$refs.menu.save(date);
      if (this.dates.length > 1) {
        let fecha_inicial = this.dates[0];
        let fecha_final = this.dates[1];
        if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
          let lastDate = this.dates[1];
          this.dates = [];
          this.dates[0] = lastDate;
        } else {
          this.$store.commit("setRangeDateGlobal", this.dates);
          this.$emit("setRangeDate", {
            tStart: fecha_inicial,
            tEnd: fecha_final,
          });
          this.sDates = fecha_inicial + "-" + fecha_final;
        }
      }
    },
  },
  computed: {
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
  },
  watch: {
    sSearch() {
      this.$emit("setSearch", this.sSearch);
    },
    dates() {
      if (this.dates !== null) {
        if (this.dates.length > 1) {
          this.bAddDates = true;
          let fecha_inicial = this.dates[0];
          let fecha_final = this.dates[1];
          if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
            let lastDate = this.dates[1];
            this.dates = [];
            this.dates[0] = lastDate;
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.$emit("setRangeDate", {
          tStart: null,
          tEnd: null,
        });
      }
    },
    sDates() {
      if (this.sDates == null) {
        this.dates = [];
        this.$refs.menu.save([]);
        this.$emit("setRangeDate", {
          tStart: null,
          tEnd: null,
        });
      }
    },
    sTransactionType() {
      this.$emit("setTransactionType", this.sTransactionType);
    },
    sRegistrationType() {
      this.$emit("setRegistrationType", this.sRegistrationType);
    },
  },
};
</script>
<style scoped>
.content-searchbar-transation-account {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 15px;
}

.content-searchbar-textfield {
  width: 100%;
  display: flex;
}
.text-field-search {
  margin-right: 10px;
}

.content-btn-filter-add {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.content-filter {
  margin-left: 15px;
}

.content-btn-add-transation {
  width: 200px;
  margin-left: 15px;
}

.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}

.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filters-Account {
    display: block;
  }

  .content-searchbar-transation-account {
    display: block;
    width: 100%;
  }

  .content-searchbar-textfield {
    width: 100%;
    margin-bottom: 10px;
    display: block;
  }
  .text-field-search {
    margin-right: 0px;
  }

  .content-filter {
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .content-btn-add-transation {
    width: 100%;
    margin-left: 0px;
  }

  .content-btn-filter-add {
    display: block;
    width: 100%;
    justify-content: flex-end;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  /* .content-filters-Account {
    display: block;
  }
  .content-searchbar-transation-account {
    display: block;
    width: 100%;
  }
  .content-searchbar-textfield {
    width: 100%;
    margin-bottom: 10px;
  }
  .content-filter {
    margin-left: 0px;
    margin-bottom: 15px;
  }
  .content-btn-add-transation {
    width: 100%;
    margin-left: 0px;
  } */
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
